<template>
  <div id="patup">
    <v-dialog
      v-model="checkInDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title
          class="text-h5"
        >
          <sweetalert-icon icon="success" />
          Check-In Successful
        </v-card-title>
        <!--        <v-card-title-->
        <!--          v-else-->
        <!--          v-if="getActiveClient.subscription"-->
        <!--          class="text-h5"-->
        <!--        >-->
        <!--          <sweetalert-icon icon="error" />-->
        <!--          Check-In Error.<br>-->
        <!--          Please verify subscription with receiptionist.-->
        <!--        </v-card-title>-->
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            text
            @click="closeCheckinDialog"
          >
            <v-icon
              class="red--text"
              left
            >
              mdi-close
            </v-icon> Close
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="checkInError"
      max-width="500px"
    >
      <v-card>
        <v-card-title
          class="text-h5"
        >
          <sweetalert-icon icon="error" />
          Check-In Error.<br>
          Please verify subscription with receiptionist.
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            text
            @click="closeCheckinDialog"
          >
            <v-icon
              class="red--text"
              left
            >
              mdi-close
            </v-icon> Close
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="12"
        style="text-align: center"
        class="my-8"
      >
        <h1 class="primary--text text-h1">
          Welcome, Please Check-In
        </h1>
      </v-col>
    </v-row>
    <v-container
      fluid
    >
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <v-toolbar
            key="prod"
            color="primary"
            class=""
            elevation-8
            style="border-radius: 30px"
          >
            <!--            <v-toolbar-title>Find Client:</v-toolbar-title>-->
            <v-autocomplete
              v-model="select"
              :loading="loading"
              :items="client_list"
              :search-input.sync="search"
              class="mx-3 white"
              flat
              persistent-hint
              clearable
              hide-no-data
              hide-details
              label="Start typing client name..."
              solo
              prepend-inner-icon="mdi-magnify"
            />
          </v-toolbar>
        </v-col>
      </v-row>

      <!--CLIENT DETAILS FOR CHECK-IN-->
      <v-row style="margin-top:150px">
        <v-col
          cols="12"
          sm="12"
          md="4"
          offset-md="4"
          style="text-align: center"
          class="my-4"
        >
          <v-row v-if="clientActive">
            <v-col
              cols="12"
              md="12"
              sm="12"
            >
              <base-material-card
                class="v-card-profillargee"
                avatar="https://res.cloudinary.com/fitrip/image/upload/v1619487040/profile_fnnawg.png"
              >
                <v-card-text class="text-center">
                  <h4 class="text-h3 font-weight-light mb-0 black--text">
                    {{ getActiveClient.firstName+" "+getActiveClient.lastName }} <br>
                    <span class="text-caption">{{ getActiveClient.email }}</span>
                  </h4>
                  <v-btn
                    color="primary darken-1"
                    rounded
                    :loading="loader"
                    :disabled="loader"
                    large
                    class="mt-4"
                    @click="checkClientIn"
                  >
                    Check In <v-icon right>
                      mdi-arrow-right
                    </v-icon>
                  </v-btn>
                </v-card-text>
              </base-material-card>
            </v-col>
          </v-row>

          <v-row v-else>
            <v-col
              cols="12"
              md="8"
              sm="8"
              offset-md="2"
            >
              <v-img
                src="https://res.cloudinary.com/fitrip/image/upload/v1622281785/belfastlogo_1_yskbx4.png"
                width="300px"
              />
            </v-col>
            <v-col
              class="mt-4"
              cols="12"
              md="12"
              sm="12"
            >
              <h2 class="primary--text">
                Don't have an account?
              </h2>
              <v-btn
                large
                class="mt-8 primary text-h5 font-weight-bold"
                @click="$router.push('/client_signup')"
              >
                <v-icon left>
                  mdi-plus
                </v-icon>  Sign Up
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="10"
        />
      </v-row>
    </v-container>
  </div>
</template>

<script>
    /* eslint-disable */
    import {mapGetters} from 'vuex';
  const axios = require('axios')
  export default {
    name: 'SearchClients',
    $_veeValidate: {
      validator: 'new',
    },
    components: {

    },
    data: () => ({
      checkInError:false,
      checkInDialog:false,
      loader: false,
      loading: false,
      inv_loading: false,
      sales_active: false,
      clientActive:false,
      err:[],
      clients: [

      ],
      client_list: [],
      search: null,
      select: null,

    }),
    computed: {
        ...mapGetters(['getActiveClient','getUserData']),
      apiSet () {
        return this.$store.getters.getApi
      },

    },
    watch: {
        search(val) {
            if(val.length === 4){
                val && val !== this.select && this.querySelections(val)
            }

        },

        select(val) {
            if(val){
                this.clientActive = !!val;
                const res = this.clients.filter(function (e) {
                    return e.firstName+" "+e.lastName === val
                });
                this.$store.commit('setActiveClient', res[0]);
                this.client_list = []
            }

        },
    },

    created () {
        this.hideHead()
      // this.fetchClients()
    },
      beforeRouteLeave (to, from, next) {
              this.$store.commit('setBlankSlate', true)
              next()
      },

    methods: {
        checkClientIn(){
            let self = this;
            this.loader = true;
            // self.checkInDialog=true

            let checkData = {
               user:this.getActiveClient._id,
               company:this.getUserData.company._id,
               branch:this.getUserData.branch._id,
           }

            axios.post(this.apiSet.checkins, checkData)
                .then(function (response) {
                    self.loader = false
                    self.clientActive=false
                        self.$store.commit('setSnack', { t: 'Check-In Successful.', c: 'green darken-3' })
                   self.checkInDialog=true
                })
                .catch(error => {
                    self.loader = false
                    self.clientActive=false
                    self.checkInError=true
                    self.err.push(error)
                })
        },

        hideHead () {
            this.$store.commit('setBlankSlate', false)
        },

      setClients () {
        const self = this
        const dict = []
        const obj = this.clients
        for (let key = 0, len = obj.length; key < len; key++) {
          dict.push(
            obj[key].firstName+' '+obj[key].lastName,
          )
        }
        this.client_list = dict
          this.loading = false;
      },

        fetchClients () {
            const self = this
            axios.post(this.apiSet.searchClients, {keyword:this.search})
                .then(response => {
                    const obj = response.data.clients
                    self.clients = obj
                    self.total = obj.length
                    self.setClients()

                }).catch(
                e => {
                    self.loader = false
                    this.err.push(e)
                },
            )
        },

      querySelections () {
        this.loading = true
        // Simulated ajax query
          this.fetchClients();
      },
        closeCheckinDialog() {
        this.checkInDialog = false
        this.checkInError = false
      },

    },

  }
</script>

<style scoped>
  #patup {
    /*background:rgba(255,255,255,0.9);*/
    background:url('https://res.cloudinary.com/fitrip/image/upload/v1619484637/elegant-seamless-pattern-black-circles-with-gold-dots-on-dark-background-texture-vector_n5pntd.jpg');
    border:4px solid rgba(255,255,255,0.6);
    border-radius: 10px;
    height: 100%;
  }
  .pulse {
    cursor: pointer;
    border-radius:50px;
    box-shadow: 0 0 0 rgba(204,169,44, 0.4);
    animation: pulse 1s infinite;
  }

  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(22, 160, 133, 0.4);
    }
    70% {
      -webkit-box-shadow: 0 0 0 10px rgba(22, 160, 133, 0);
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(22, 160, 133, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(22, 160, 133, 0.4);
      box-shadow: 0 0 0 0 rgba(22, 160, 133, 0.4);
    }
    70% {
      -moz-box-shadow: 0 0 0 10px rgba(22, 160, 133, 0);
      box-shadow: 0 0 0 10px rgba(22, 160, 133, 0);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(22, 160, 133, 0);
      box-shadow: 0 0 0 0 rgba(22, 160, 133, 0);
    }
  }

  .centerup{
    font-size:30px;
    background-color: #e3e3e3;
    /*background-color: #666666;*/
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    color: transparent;
    /*text-shadow: rgba(255,255,255,0.5) 0px 3px 3px;*/
  }
</style>
